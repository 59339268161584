import React from "react";
import { FormattedMessage } from "react-intl";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useLanguage } from "../../contexts/LanguageContext"; // Đảm bảo đã import useLanguage

const Privacy = () => {
  const { locale } = useLanguage(); // Lấy ngôn ngữ hiện tại từ context
  const fontClass = locale === "vi" ? "font-helvetica" : "font-sathu"; // Xác định class cho font dựa trên ngôn ngữ

  return (
    <div className={`flex flex-col justify-center items-center bg-white ${fontClass}`}>
      <Header />

      <div className="flex gap-4 flex-col justify-center text-base font-normal bg-white pt-[92px] px-4 md:px-10 lg:px-32 pb-10 border-b border-gray-200">
        <h1 className="w-full text-center text-3xl font-bold pt-6 uppercase">
          <FormattedMessage id="privacy.title" />
        </h1>
        <h2 className="text-2xl font-semibold">
          <FormattedMessage id="privacy.title1" />
        </h2>
        <p>
          <FormattedMessage id="privacy.description1" />
        </p>
        <h2 className="text-2xl font-semibold">
          <FormattedMessage id="privacy.title2" />
        </h2>
        <p>
          <FormattedMessage id="privacy.description2" />
        </p>
        <h2 className="text-2xl font-semibold">
          <FormattedMessage id="privacy.title3" />
        </h2>
        <p>
          <FormattedMessage id="privacy.description3.1" />
        </p>
        <p>
          <FormattedMessage id="privacy.description3.2" />
        </p>
        <h2 className="text-2xl font-semibold">
          <FormattedMessage id="privacy.title4" />
        </h2>
        <p>
          <FormattedMessage id="privacy.description4" />
        </p>
        <h2 className="text-2xl font-semibold">
          <FormattedMessage id="privacy.title5" />
        </h2>
        <p>
          <FormattedMessage id="privacy.description5" />
        </p>
        <h2 className="text-2xl font-semibold">
          <FormattedMessage id="privacy.title6" />
        </h2>
        <p>
          <FormattedMessage id="privacy.description6" />
        </p>
        <h2 className="text-2xl font-semibold">
          <FormattedMessage id="privacy.title7" />
        </h2>
        <p>
          <FormattedMessage id="privacy.description7" />
        </p>
      </div>

      <Footer />
    </div>
  );
};

export default Privacy;
