import React from "react";
import Layout from "../../components/Layout";
import { FormattedMessage } from "react-intl";
import { useLanguage } from "../../contexts/LanguageContext"; // Đảm bảo đã import useLanguage
import { Link } from "react-router-dom";

const AboutPage = () => {
  const { locale } = useLanguage(); 
  const fontClass = locale === "vi" ? "font-helvetica" : "font-sathu";

  return (
    <Layout>
      <div className={`flex flex-col-reverse md:flex-row md:py-[100px] px-4 md:px-0 pb-4 md:pb-0 gap-10 ${fontClass}`}>
        <div className="text-white text-sm md:text-lg md:my-4 flex flex-col gap-10 md:gap-16 w-full md:w-1/2">
          <h2>
            <FormattedMessage id="about.title" />
          </h2>
          <p className="font-medium">
            <FormattedMessage id="about.content1" />
          </p>
          <p className="font-light">
            <FormattedMessage id="about.content2" />
          </p>
          <Link to="/contact" className="px-12 py-2 bg-[#bb8972] w-fit">
            <FormattedMessage id="contact.form" />
          </Link>
        </div>
        <div className="relative flex my-auto justify-center items-center w-full md:w-1/2">
          <div className="absolute bottom-0 w-full h-20 bg-gradient-to-t from-secondary to-transparent"></div>
          <div className="absolute left-0 w-10 h-full bg-gradient-to-r from-secondary to-transparent"></div>
          <div className="absolute right-0 w-10 h-full bg-gradient-to-l from-secondary to-transparent"></div>
          <div className="absolute top-0 w-full h-20 bg-gradient-to-b from-secondary to-transparent"></div>
          <img
            src="../assets/images/video.gif"
            alt=""
            className="w-full md:w-[700px] object-cover"
          />
        </div>
      </div>
    </Layout>
  );
};

export default AboutPage;