import React from "react";
import Layout from "../../components/Layout";
import { FormattedMessage } from "react-intl";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLanguage } from "../../contexts/LanguageContext"; // Đảm bảo đã import useLanguage
import { Link } from "react-router-dom";

const partnerImages = [
  "https://profhilo.com.vn/wp-content/uploads/2023/05/8-1.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/01/Clinic_03.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/03/Aura-clinic-1.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/01/Clinic_21.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/01/Clinic_34.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/01/Clinic_45.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/08/Tam-Anh.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/05/5.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/01/Clinic_46.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/01/Clinic_05.png",
  "https://profhilo.com.vn/wp-content/uploads/2024/06/JT-angel.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/01/Clinic_10.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/08/JW-Hospital.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/05/3.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/01/Clinic_13.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/05/7.png",
  "https://profhilo.com.vn/wp-content/uploads/2024/01/Da-Viet.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/08/DHA.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/01/Clinic_08.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/01/Clinic_37.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/09/Doctor-Beauty.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/01/2-1.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/09/Dr-Thao-Le.png",
  "https://profhilo.com.vn/wp-content/uploads/2024/06/IMG_1277.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/01/Clinic_43.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/01/Clinic_07.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/05/Goncy-new-logo.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/08/JK.png",
  "https://profhilo.com.vn/wp-content/uploads/2024/05/Khoe-Dep-Clinic-website-logo.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/01/Clinic_39-1.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/06/Lavish.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/07/96.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/01/Clinic_01.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/07/97.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/06/Muse.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/11/profhilo-clinics.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/09/Oi.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/05/86.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/02/8.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/01/Clinic_02.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/05/7-2.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/07/profhilo-clinics.png",
  "https://profhilo.com.vn/wp-content/uploads/2024/07/1.png",
  "https://profhilo.com.vn/wp-content/uploads/2024/05/Dr-Thai-Ha.png",
  "https://profhilo.com.vn/wp-content/uploads/2024/07/2.png",
  "https://profhilo.com.vn/wp-content/uploads/2024/01/Da-Viet.png",
  "https://profhilo.com.vn/wp-content/uploads/2024/03/Dr-YH.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/08/SV-clinic.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/05/7-1.png",
  "https://profhilo.com.vn/wp-content/uploads/2024/01/C84C33E6-F9D5-4B64-89D4-9135FD583FDB.png",
  "https://profhilo.com.vn/wp-content/uploads/2024/05/Citrine-website.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/01/Clinic_41.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/08/Thuy-Tien.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/12/PK-DL-Bs.-Ng%E1%BB%8Dc-Di%E1%BB%87p.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/01/Clinic_17.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/01/Clinic_35.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/01/Clinic_48.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/09/SHynh-House.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/01/Clinic_24.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/05/85.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/01/sian.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/01/Clinic_30.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/01/Clinic_25.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/01/Clinic_29.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/06/NEvada.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/09/thuy-dung.png",
  "https://profhilo.com.vn/wp-content/uploads/2024/08/Tham-my-Le-Hoai.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/08/Uyen-Anh.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/12/Tham-my-Ngoc-Diep.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/05/Gin-Beauty.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/05/84.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/04/profhilo-clinics.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/05/9-1.png",
  "https://profhilo.com.vn/wp-content/uploads/2024/05/Vanity-website.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/01/Clinic_18.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/05/Cao-Kim.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/05/4.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/08/Nguyen-Du.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/01/4.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/07/95.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/06/Xuan-Huong.png",
  "https://profhilo.com.vn/wp-content/uploads/2023/05/5-2.png",
  "https://profhilo.com.vn/wp-content/uploads/2024/01/world-beauty.png",
];

const PartnerPage = () => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const { locale } = useLanguage();
  const fontClass = locale === "vi" ? "font-helvetica" : "font-sathu";

  return (
    <Layout>
      <div className={`flex flex-col justify-center items-center h-80 gap-6 my-10 ${fontClass}`}>
        <h2 className="text-white uppercase text-4xl font-bold">
          <FormattedMessage id="partner.title" />
        </h2>
        <p className="text-white text-base font-normal w-2/3 text-center">
          <FormattedMessage id="partner.description" />
        </p>
        <Link to="/contact" className="bg-white text-primary px-4 py-2 rounded-md">
          <FormattedMessage id="partner.button" />
        </Link>
      </div>
      <div className="w-full px-4 pb-20">
        <Slider {...settings}>
          {partnerImages.map((image, index) => (
            <div key={index} className="px-2">
              <img
                src={image}
                alt={`Đối tác ${index + 1}`}
                className="w-full h-auto md:h-40 object-contain"
              />
            </div>
          ))}
        </Slider>
      </div>
    </Layout>
  );
};

export default PartnerPage;