import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { useLanguage } from "../../contexts/LanguageContext"; // Đảm bảo đã import useLanguage

const Footer = () => {
  const { locale } = useLanguage(); // Lấy ngôn ngữ hiện tại từ context
  const fontClass = locale === "vi" ? "font-helvetica" : "font-sathu"; // Xác định class cho font dựa trên ngôn ngữ

  return (
    <div className={`w-full p-4 md:px-10 lg:px-32 bg-white md:pt-[58px] ${fontClass}`}>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-14 pb-7">
        <div className="flex flex-col gap-8">
          <img
            src="../assets/images/logo1.png"
            alt=""
            className="h-20 w-32 object-contain"
          />
          <p className="text-primary text-base font-normal">
            <FormattedMessage id="footer.description" />
          </p>
          <a href="tel:+84379745357" className="flex items-center gap-2">
            <img src="../assets/logos/phone.png" alt="" className="w-6 h-6"/>
            <span className="text-primary text-base font-normal">
              +84 379745357
            </span>
          </a>
          <a href="mailto:info@nexzenah.com" className="flex items-center gap-2">
            <img src="../assets/logos/email.png" alt="" className="w-6 h-6"/>
            <span className="text-primary text-base font-normal">
              info@nexzenah.com
            </span>
          </a>
          <a className="flex items-center gap-2">
            <img src="../assets/logos/location.png" alt="" className="w-6 h-6"/>
            <span className="text-primary text-base font-normal">
              Hồ Chí Minh, Việt Nam
            </span>
          </a>
        </div>

        <div className="flex flex-col gap-8 md:text-end">
          <h2 className="uppercase text-primary text-xl font-bold">
            <FormattedMessage id="page" />
          </h2>
          <div className="flex flex-col w-full justify-end gap-3">
            <Link to="/">
              <span className="text-primary w-fit text-base font-semibold">
                <FormattedMessage id="header.home" />
              </span>
            </Link>
            <Link to="/about">
              <span className="text-primary w-fit text-base font-semibold">
                <FormattedMessage id="header.about" />
              </span>
            </Link>
            <Link to="/products">
              <span className="text-primary w-fit text-base font-semibold">
                <FormattedMessage id="header.products" />
              </span>
            </Link>
          </div>
        </div>

        <div className="flex flex-col gap-8 md:text-end">
          <h2 className="uppercase text-primary text-xl font-bold">
            <FormattedMessage id="policies" />
          </h2>
          <div className="flex flex-col gap-3">
            <Link to="/terms">
              <span className="text-primary text-base font-semibold">
                <FormattedMessage id="footer.terms" />
              </span>
            </Link>
            <Link to="/privacy">
              <span className="text-primary text-base font-semibold">
                <FormattedMessage id="footer.privacy" />
              </span>
            </Link>
            <Link to="/contact">
              <span className="text-primary text-base font-semibold">
                <FormattedMessage id="header.contact" />
              </span>
            </Link>
          </div>
        </div>
      </div>
      <hr className="border-primary border-1" />
      <div className="flex justify-between items-center py-6">
        <div className="text-primary text-base font-normal">
          <FormattedMessage id="footer.copyright" />
        </div>
      </div>
    </div>
  );
};

export default Footer;