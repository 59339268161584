import React, { useState } from "react";
import axios from "axios";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { FormattedMessage } from "react-intl";
import { useLanguage } from "../../contexts/LanguageContext"; // Đảm bảo đã import useLanguage

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const { locale } = useLanguage(); // Lấy ngôn ngữ hiện tại từ context
  const fontClass = locale === "vi" ? "font-helvetica" : "font-sathu"; // Xác định class cho font dựa trên ngôn ngữ

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "http://localhost:4000/submit",
        formData
      );
      console.log("Phản hồi từ backend:", response.data);
      alert("Thông tin đã được gửi thành công");
    } catch (error) {
      console.error("Có lỗi xảy ra khi gửi thông tin", error);
      alert("Có lỗi xảy ra khi gửi thông tin");
    }
  };

  return (
    <div className={`flex flex-col justify-center items-center ${fontClass}`}>
      <Header />
      <div className="w-full mt-[92px] bg-black text-white">
        <div
          className="flex items-center bg-cover bg-center bg-no-repeat min-h-[calc(100vh-92px)] px-4 md:px-28 py-10"
          style={{
            backgroundImage: "url('/assets/images/bgContact.png')",
          }}
        >
          <div className="w-full lg:w-1/2 flex flex-col gap-9">
            <h1 className="text-4xl md:text-6xl lg:text-[80px] leading-tight md:leading-[85px]">
              <FormattedMessage id="contact.title" />
            </h1>
            <p className="font-medium text-lg md:text-2xl">
              <FormattedMessage id="contact.description" />
            </p>
          </div>
        </div>
        <form
          className="bg-white text-black flex flex-col items-center justify-center p-4 md:p-8 py-7"
          onSubmit={handleSubmit}
        >
          <h1 className="text-3xl md:text-4xl lg:text-[50px] font-semibold mb-6">
            <FormattedMessage id="contact.form" />
          </h1>
          <div className="flex flex-col w-full max-w-3xl gap-7">
            <p className="font-light text-base">
              <FormattedMessage id="contact.form.note" />
            </p>
            <div className="flex flex-col md:flex-row gap-7">
              <p className="flex flex-col w-full">
                <label
                  className="text-base font-bold text-gray-800"
                  htmlFor="name"
                >
                  <FormattedMessage id="contact.name" />
                  <span className="text-red-500">*</span>
                </label>
                <input
                  className="border border-gray-400 px-4 py-2 rounded-md"
                  type="text"
                  id="name"
                  placeholder="Name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </p>
              <p className="flex flex-col w-full">
                <label
                  className="text-base font-bold text-gray-800"
                  htmlFor="email"
                >
                  <FormattedMessage id="contact.email" />
                  <span className="text-red-500">*</span>
                </label>
                <input
                  className="border border-gray-400 px-4 py-2 rounded-md"
                  type="text"
                  id="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </p>
            </div>
            <div className="flex flex-col w-full">
              <label
                className="text-base font-bold text-gray-800"
                htmlFor="message"
              >
                <FormattedMessage id="contact.message" />
              </label>
              <textarea
                className="border border-gray-400 px-4 py-2 rounded-md"
                id="message"
                type="text"
                placeholder="Message"
                cols="30"
                rows="10"
                value={formData.message}
                onChange={handleChange}
              ></textarea>
            </div>
            <div className="flex justify-end">
              <button
                className="bg-[#d7a286] text-white px-10 py-3"
                type="submit"
              >
                <FormattedMessage id="contact.submit" />
              </button>
            </div>
          </div>
        </form>

        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d250860.5426950774!2d106.49654539028424!3d10.757897550916532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x317529292e8d3dd1%3A0xf15f5aad773c112b!2zVGjDoG5oIHBo4buRIEjhu5MgQ2jDrSBNaW5oLCBI4buTIENow60gTWluaCwgVmnhu4d0IE5hbQ!5e0!3m2!1svi!2s!4v1727702133251!5m2!1svi!2s"
          className="w-full h-[300px] md:h-[450px]"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Bản đồ Thành phố Hồ Chí Minh"
        ></iframe>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;