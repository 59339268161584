import React from "react";
import Layout from "../../components/Layout";
import { FormattedMessage } from "react-intl";
import { useLanguage } from "../../contexts/LanguageContext"; // Đảm bảo đã import useLanguage

const Products = () => {
  const { locale } = useLanguage(); // Lấy ngôn ngữ hiện tại từ context
  const fontClass = locale === "vi" ? "font-helvetica" : "font-sathu"; // Xác định class cho font dựa trên ngôn ngữ

  return (
    <Layout>
      <div className={`flex flex-col gap-14 p-4 pb-10 md:p-0 md:py-[100px] text-white ${fontClass}`}>
        <div className="flex flex-col gap-7">
          <h2 className="uppercase text-2xl">
            <FormattedMessage id="product.title" />
          </h2>
          <p className="text-base">
            <FormattedMessage id="product.description" />
          </p>
        </div>
        <div className="flex flex-col gap-32 w-full justify-center items-center">
          <div className="flex gap-10">
            <div className="flex flex-col justify-center gap-5">
              <h2 className="text-2xl font-bold">Coming Soon</h2>
              <p className="text-base">
                ... 
              </p>
              <button className="bg-white text-primary px-5 py-2 rounded-md w-fit">
                Read More
              </button>
            </div>

            <div className="relative w-fit">
              <div className="absolute bottom-[-20px] left-[20px] w-full h-full md:w-[400px] md:h-[400px] bg-blue-500 z-0"></div>
              <div className="relative bg-sky-200 w-full h-full md:w-[400px] md:h-[400px]">
                <img
                  src="../assets/images/coming1.jpg"
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
          </div>

          <div className="flex gap-10">
            <div className="relative w-fit">
              <div className="absolute bottom-[-20px] left-[20px]  w-full h-full md:w-[400px] md:h-[400px] bg-blue-500 z-0"></div>
              <div className="relative bg-sky-200  w-full h-full md:w-[400px] md:h-[400px]">
                <img
                  src="../assets/images/coming1.jpg"
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
            <div className="flex flex-col justify-center gap-5">
              <h2 className="text-2xl font-bold">Coming Soon</h2>
              <p className="text-base">
                ... 
              </p>
              <button className="bg-white text-primary px-5 py-2 rounded-md w-fit">
                Read More
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Products;
