import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { FormattedMessage } from "react-intl";
import { useLanguage } from "../../contexts/LanguageContext"; // Đảm bảo đã import useLanguage

const Terms = () => {
  const { locale } = useLanguage(); // Lấy ngôn ngữ hiện tại từ context
  const fontClass = locale === "vi" ? "font-helvetica" : "font-sathu"; // Xác định class cho font dựa trên ngôn ngữ

  return (
    <div className={`flex flex-col justify-center items-center bg-white ${fontClass}`}>
      <Header />

      <div className="flex gap-4 flex-col justify-center text-base font-normal bg-white pt-[92px] px-4 md:px-10 lg:px-32 pb-10 border-b border-gray-200">
        <h1 className="w-full text-center text-3xl font-bold pt-6 uppercase">
          <FormattedMessage id="terms.title" />
        </h1>
        <h2 className="text-2xl font-semibold">
          <FormattedMessage id="terms.title1" />
        </h2>
        <p>
          <FormattedMessage id="terms.description1" />
        </p>
        <p>
          <FormattedMessage id="terms.description1.1" />
        </p>
        <p>
          <FormattedMessage id="terms.description1.2" />
        </p>
        <h2 className="text-2xl font-semibold">
          <FormattedMessage id="terms.title2" />
        </h2>
        <p>
          <FormattedMessage id="terms.description2" />
        </p>
        <p>
          <FormattedMessage id="terms.description2.1" />
        </p>

        <h2 className="text-2xl font-semibold">
          <FormattedMessage id="terms.title3" />
        </h2>
        <p>
          <FormattedMessage id="terms.description3" />
        </p>
        <p>
          <FormattedMessage id="terms.title3.1" />
          <br />
          <FormattedMessage id="terms.description3.1" />
        </p>
        <p>
          <FormattedMessage id="terms.title3.2" />
          <br />
          <FormattedMessage id="terms.description3.2" />
        </p>
        <p>
          <FormattedMessage id="terms.title3.3" />
        </p>
        <h2 className="text-2xl font-semibold">
          <FormattedMessage id="terms.title4" />
        </h2>
        <p>
          <FormattedMessage id="terms.description4" />
        </p>
        <p>
          <FormattedMessage id="terms.title4.1" />
          <br />

          <FormattedMessage id="terms.description4.1" />
        </p>
        <p>
          <FormattedMessage id="terms.title4.2" />
          <br />

          <FormattedMessage id="terms.description4.2" />
        </p>
        <p>
          <FormattedMessage id="terms.title4.3" />
          <br />
          <FormattedMessage id="terms.description4.3" />
        </p>

        <h2 className="text-2xl font-semibold">
          <FormattedMessage id="terms.title5" />
        </h2>
        <p>
          <FormattedMessage id="terms.description5" />
        </p>
        <p>
          <FormattedMessage id="terms.title5.1" />
          <br />
          <FormattedMessage id="terms.description5.1" />
        </p>
        <p>
          <FormattedMessage id="terms.title5.2" />
          <br />
          <FormattedMessage id="terms.description5.2" />
        </p>
        <p>
          <FormattedMessage id="terms.title5.3" />
          <br />
          <FormattedMessage id="terms.description5.3.1" />
          <br />
          <br />
          <FormattedMessage id="terms.description5.3.2" />
        </p>
        <p>
          <FormattedMessage id="terms.title5.4" />
          <br />
          <FormattedMessage id="terms.description5.4.1" />
          <br />
          <br />
          <FormattedMessage id="terms.description5.4.2" />
        </p>
      </div>

      <Footer />
    </div>
  );
};

export default Terms;
