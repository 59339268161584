import React, { createContext, useState, useContext, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import Vietnamese from '../locales/vi.json';
import English from '../locales/en.json';

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [locale, setLocale] = useState(localStorage.getItem('locale') || 'vi');

  const messages = {
    vi: Vietnamese,
    en: English,
  };

  const toggleLanguage = () => {
    const newLocale = locale === 'vi' ? 'en' : 'vi';
    localStorage.setItem('locale', newLocale);
    setLocale(newLocale);
  };

  useEffect(() => {
    localStorage.setItem('locale', locale);
  }, [locale]);

  return (
    <LanguageContext.Provider value={{ locale, toggleLanguage }}>
      <IntlProvider messages={messages[locale]} locale={locale}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
