import React from 'react'
import Layout from '../../../components/Layout'

const ProductDetail = () => {
  return (
    <Layout>
      s
    </Layout>
  )
}

export default ProductDetail
